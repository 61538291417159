<template>
  <div class="xinjiaren-detail">
    <div v-if="!status" class="xinjiaren-detail-emoty">
      <van-empty description="课程不存在" />
    </div>
    <div v-else>
      <div class="detail-video" @click="judgePhoneType">
        <img :src="coverImg" alt="" class="detail-img" />
        <div class="detail-video-cover">
          <img
            class="detail-video-play"
            src="../../assets/img/play.png"
            alt=""
          />
        </div>
        <WxOpen v-if="isWeixin"> </WxOpen>
      </div>
      <van-tabs v-model="active" color="#4e7fd3" sticky>
        <van-tab
          v-for="(item, index) in tabList"
          :title="item.name"
          :name="item.value"
          :key="index"
        >
          <template #title>
            <div class="tab-title">
              {{ item.name }}
              <template v-if="item.value === 'Evaluate' && total > 0"
                >(
                <div class="tab-title-evaluate">{{ total }}</div>
                )</template
              >
            </div>
          </template>
          <component :is="item.value" :tabKey="active">
            <WxOpen v-if="isWeixin"> </WxOpen>
          </component>
        </van-tab>
      </van-tabs>
      <van-tabbar
        v-if="active === 'Catalogue'"
        v-model="barActive"
        active-color="#fff"
        inactive-color="#fff"
        style="overflow: hidden"
      >
        <van-tabbar-item @click="judgePhoneType">
          收藏
          <template #icon>
            <img src="../../assets/img/collection.png" />
          </template>
        </van-tabbar-item>
        <van-tabbar-item @click="judgePhoneType">
          分享
          <template #icon>
            <img src="../../assets/img/share.png" />
          </template>
        </van-tabbar-item>
        <van-tabbar-item @click="judgePhoneType">
          客服
          <template #icon>
            <img src="../../assets/img/customer_service.png" />
          </template>
        </van-tabbar-item>
        <WxOpen v-if="isWeixin"> </WxOpen>
      </van-tabbar>
      <div
        class="evaluate-bottom"
        v-if="active === 'Evaluate'"
        @click="judgePhoneType"
      >
        <div class="evaluate-bottom-input">请输入您的评价</div>
        <WxOpen v-if="isWeixin"> </WxOpen>
      </div>
    </div>
    <div class="btn-group" @click="judgePhoneType">
      <div class="float-wrap">
        <div class="float-logo-wrap">
          <img
            class="float-logo"
            src="../../assets/img/img_share_logo_s.png"
            alt=""
          />
        </div>
        <div class="float-text">打开App</div>
      </div>
      <WxOpen v-if="isWeixin" > </WxOpen>
    </div>
    <!-- <div class="btn-group-2" >
      <div class="float-wrap">
        <div class="float-logo-wrap">
          <img
            class="float-logo"
            src="../../assets/img/img_share_logo_s.png"
            alt=""
          />
        </div>
        <a href="intent://wechatextmsg/#Intent;scheme=wechatextmsg;package=http://com.mm.example.starfamilypro;end"> Take a QR code </a>
复制代码
        <div class="float-text">浏览器打开App</div>
      </div>
    </div> -->
  </div>
</template>

<script>
import Catalogue from "./components/Catalogue";
import Evaluate from "./components/Evaluate";
import Synopsis from "./components/Synopsis";
import WxOpen from "./components/WxOpen.vue";
import wx from "weixin-js-sdk";
import { Dialog } from "vant";
import CallApp from "callapp-lib";

export default {
  components: {
    Catalogue,
    Evaluate,
    Synopsis,
    WxOpen,
  },
  data() {
    return {
      tabList: [
        {
          name: "简介",
          value: "Catalogue",
        },
        {
          name: "目录",
          value: "Synopsis",
        },
        {
          name: "评价",
          value: "Evaluate",
        },
      ],
      total: 0,
      active: "Synopsis",
      barActive: 0,
      status: false,
      courseId: this.$route.query.courseId,
      dataStr: "",
      coverImg: "",
      isWeixin: false,
      isAndroid: false,
      isIOS: false,
    };
  },
  watch: {
    active: {
      handler(val) {
        console.log(val);
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.getStatus();
  },
  mounted() {
    this.checkWx();
    let data = {
      uri: `gimmc://www.gimmccc.com/common/webview?url=${encodeURIComponent(
        window.location.origin + "/info/detail?id=48&goindex=1"
      )}`,
    };
    this.dataStr = JSON.stringify(data);
  },
  methods: {
    toBrowser() {
      // window.location.href = `wechatextmsg://com.mm.example.starfamilypro?id=${this.courseId}`;
      // window.location.href = `intent://wechatextmsg/#Intent;scheme=wechatextmsg;package=http://com.mm.example.starfamilypro;end`;
      const options = {
        scheme: {
          protocol: "wechatextmsg", //APP 协议
          host:"com.mm.example.starfamilypro"
        },
        intent: {
          package: "com.mm.example.starfamilypro",
          scheme: "wechatextmsg",
        },
        universal: {
          host: "xingjiaren-h5.hailiangedu.com",
        },
        appstore:
          "https://apps.apple.com/cn/app/%E6%98%9F%E5%AE%B6%E4%BA%BA/id1582745268?from=groupmessage&isappinstalled=0",
        yingyongbao: "https://sj.qq.com/appdetail/com.mm.example.starfamilypro",
        timeout: 1000,
        fallback: "https://xingjiaren-api.hailiangedu.com/app/download",
      };
      const callLib = new CallApp(options);
      callLib.open({
        param: {
          id: this.courseId,
        },
        path: "coursedetail",
      });
    },
    openUrl() {
      this.judgePhoneType();
    },
    judgePhoneType() {
      var u = navigator.userAgent;
      var isWeixin = u.toLowerCase().indexOf("micromessenger") !== -1; // 微信内
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android终端
      var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      this.isWeixin = isWeixin;
      this.isIOS = isIOS;
      this.isAndroid = isAndroid;
      // 微信内
      if (isWeixin) {
        this.judgeNavigator();
        // Dialog.alert({ message: "微信浏览器" });
      } else {
        // if (isAndroid) {
        //   // Dialog.alert({ message: "isAndroid" });
        // }
          Dialog.confirm({
            title: "",
            message: '即将离开微信,打开"星家人"',
          })
            .then(() => {
              this.toBrowser();
            })
            .catch(() => {});
      }
    },
    judgeNavigator() {
      // 微信版本号大于 7.0.12 开放标签才可进行 唤醒 app 跳转
      const wechatInfo = window.navigator.userAgent.match(
        /MicroMessenger\/([\d\.]+)/i // eslint-disable-line
      );
      if (!wechatInfo) return;
      let judgewechat = wechatInfo[1].split(".");
      let flag = false;
      if (judgewechat[0] >= 7) {
        if (judgewechat[1] >= 0) {
          if (judgewechat[2] >= 12) {
            this.wechatState = true;
            console.log("当前符合 h5 打开指定app");
            flag = true;
          }
        }
      }
      if (!flag) Dialog.alert({ message: "请升级当前微信版本" });
    },
    ready(value) {
      console.log("ready", value);
    },
    launch(value) {
      console.log("launch", value);
    },
    error(value) {
      console.log("error", value);
    },
    checkWx() {
      var u = navigator.userAgent;
      var isWeixin = u.toLowerCase().indexOf("micromessenger") !== -1; // 微信内
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android终端
      var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      this.isWeixin = isWeixin;
      this.isIOS = isIOS;
      this.isAndroid = isAndroid;
      this.$get("/wechat/getConfig", {
        url: window.location.href.split("#")[0],
      })
        .then((res) => {
          if (res) {
            wx.config({
              // eslint-disable-line
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
              appId: res.appId, // 必填，公众号的唯一标识
              timestamp: res.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.nonceStr, // 必填，生成签名的随机串
              signature: res.signature, // 必填，签名
              jsApiList: [
                "onMenuShareAppMessage",
                "onMenuShareTimeline",
                "chooseImage",
                "previewImage",
              ], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
              openTagList: ["wx-open-launch-app"], // 获取开放标签权限
            });
          }
        })
        .catch(() => {});

      /* eslint-disable */

      wx.ready(function () {
        console.log("ready");
      });
    },
    getStatus() {
      this.$get("course/queryCourseStatus", {
        courseId: this.courseId,
      })
        .then((res) => {
          this.coverImg = res.data.coverUrl;
          this.status = true;
          this.getTotal();
        })
        .catch(() => {
          this.status = false;
        });
    },
    getTotal() {
      this.$get("course/getCourseEvaluateList", {
        pageNo: 1,
        pageSize: 10,
        courseId: this.courseId,
      })
        .then((res) => {
          this.total = res.data.total;
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.detail-video {
  position: relative;
  overflow: hidden;
}
.detail-img {
  width: 100vw;
  height: auto;
}
.detail-video-play {
  height: 100px;
  cursor: pointer;
}
.detail-video-cover {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
}
.tab-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tab-title-evaluate {
  color: rgb(215, 26, 80);
  padding: 0 8px;
}
.van-tabbar--fixed {
  background-color: rgb(62, 129, 228);
}
.van-tabbar-item--active {
  background-color: rgb(62, 129, 228);
}
.btn-group {
  position: fixed;
  right: 0;
  top: 14%;
  height: 56px;
  background: rgba(62, 129, 228, 0.9);
  border-radius: 100px 0px 0px 100px;
  overflow: hidden;
}
.btn-group-2 {
  position: fixed;
  right: 0;
  top: 24%;
  height: 56px;
  background: rgba(62, 129, 228, 0.9);
  border-radius: 100px 0px 0px 100px;
  overflow: hidden;
}

.float-wrap {
  position: relative;
  line-height: 56px;
  font-size: 10px;
  color: #ffffff;
  padding: 0.5px 20px 0 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.float-logo-wrap {
  width: 52px;
  height: 52px;
  margin: 0 10px 0 0;
  cursor: pointer;
  border-radius: 50%;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.float-logo {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.float-text {
  font-size: 20px;
}
.evaluate-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  box-sizing: border-box;
  width: 100%;
  padding: 25px;
  background-color: #fff;
  overflow: hidden;
}
.evaluate-bottom-input {
  padding: 18px 28px;
  box-sizing: border-box;
  text-align: left;
  font-size: 24px;
  color: #a1a1a1;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 34px;
}
#launch-btn {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.xinjiaren-detail {
  height: 100%;
  width: 100%;
}
.xinjiaren-detail-emoty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}
</style>

