<template>
  <div class="evaluate-content">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div v-for="(item,index) in detailData" :key="index">
        <div class="evaluate-content-info">
          <img class="evaluate-content-info-img" :src="item.headImage ? item.headImage : defaulImg" alt="">
          <div class="evaluate-content-detail">
            <div class="evaluate-content-info-user">
              {{ item.nickname }}
              <div class="evaluate-content-info-time">
                {{ getTime(item.createTime) }}
              </div>
            </div>
            <div class="evaluate-content-info-content">
              {{ item.content }}
            </div>
          </div>
        </div>
        <van-divider dashed :style="{ borderColor: '#4b4b4b' }" />
      </div>
    </van-list>
  </div>
</template>

<script>
  import dayjs from 'dayjs'
  export default {
    name: 'Evaluate',
    props:{
      tabKey:{
        type: String,
        default: ''
      }
    },
    data () {
      return {
        defaulImg: require('../../../assets/img/nomoperson.png'),
        detailData: [],
        pageNo: 1,
        finished: false,
        loading: false,
        courseId: this.$route.query.courseId
      }
    },
    watch:{
      'tabKey':{
        handler(val){
          if(val === 'Evaluate'){
            this.detailData = []
            this.getDetail()
          }
        },
        immediate: true,
        deep: true
      }
    },
    methods:{
      onLoad(){
        this.pageNo += 1
        this.getDetail()
      },
      getDetail(){
        this.loading = true
        this.$get("course/getCourseEvaluateList", {
          pageNo: this.pageNo,
          pageSize: 20,
          courseId: this.courseId
        })
          .then((evaluateData) => {
            const newData = [...this.detailData]
            const records = evaluateData.data.records
            this.detailData = newData.concat(records)
            this.loading = false
            if(this.detailData.length === evaluateData.data.total){
              this.finished = true
            }
          })
          .catch(() => {
            this.loading = false
          });
      },
      getTime(time){
        return dayjs(time).format('YYYY/MM/DD HH:mm')
      }
    }
  }
</script>

<style scoped>
.evaluate-content{
  padding: 15px 12px;
  padding-bottom: 125px;
  box-sizing: border-box;
}
.evaluate-content-info{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 15px;
}
.evaluate-content-info-img{
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.evaluate-content-detail{
  margin-left: 25px;
  width: calc(100% - 125px);
}
.evaluate-content-info-time{
  font-size: 18px;
  color: rgba(125, 125, 125, 1);
}
.evaluate-content-info-user{
  font-size: 28px;
  width: 100%;
  color: #4b4b4b;
  margin-bottom: 12px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.evaluate-content-info-content{
  color: rgba(125, 125, 125, 1);
  width: 100%;
  font-size: 24px;
}
.dishidden{
  visibility:hidden
}
</style>