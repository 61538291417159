<template>
  <div class="catalogue-content">
    <div class="catalogue-content-info">
      {{ detailData.introInfo }}
    </div>
    <div class="catalogue-content-bottom">
      <div class="catalogue-content-speaker">
        <div class="catalogue-content-item">讲师：</div>
        <div class="catalogue-content-value">{{ detailData.speaker }}</div>
      </div>
      <div class="catalogue-content-tags">
        <div
          v-for="(item, index) in detailData.tags"
          class="catalogue-content-tagitem"
          :key="index"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="catalogue-content-speakinfo">
      <img
        class="catalogue-content-speakinfo-img"
        :src="detailData.speakerCoverUrl"
        alt=""
      />
      <div class="catalogue-content-speakdetail">
        <div class="catalogue-content-speakinfo-user">
          {{ detailData.speaker }}
        </div>
        <div class="catalogue-content-speakinfo-content">
          {{ detailData.speakerIntro }}
        </div>
      </div>
    </div>
    <van-divider dashed :style="{ borderColor: '#4b4b4b' }" />
    <div class="catalogue-content-htmlinfo">
      <div class="catalogue-content-recommend-title">
        <img
          class="catalogue-content-recommend-title-img"
          src="../../../assets/img/label.png"
        />课程介绍
      </div>
      <div v-html="detailData.intro"></div>
    </div>
    <div class="catalogue-content-recommend">
      <div class="catalogue-content-recommend-title">
        <img
          class="catalogue-content-recommend-title-img"
          src="../../../assets/img/label.png"
        />相关推荐
      </div>
      <div class="catalogue-content-recommend-content">
        <div
          v-for="(item, index) in synopsisData"
          :key="index"
          class="catalogue-content-recommend-box"
          @click="judgePhoneType"
        >
          <img
            class="catalogue-content-recommend-coverUrl"
            width="100%"
            height="auto"
            :src="item.coverUrl"
          />
          <div class="catalogue-content-recommend-intro">
            {{ item.name }}
          </div>
          <div class="catalogue-content-recommend-speaker">
            <img
              class="catalogue-content-recommend-speakerCoverUrl"
              :src="item.speakerCoverUrl"
              alt=""
            />{{ item.speaker }}
          </div>
        </div>
        <WxOpen v-if="isWeixin"> </WxOpen>
      </div>
    </div>
  </div>
</template>

<script>
import WxOpen from "./WxOpen.vue";
import { Dialog } from "vant";
import CallApp from "callapp-lib";
export default {
  name: "Catalogue",
  props: {
    tabKey: {
      type: String,
      default: "",
    },
  },
  components: {
    WxOpen,
  },
  data() {
    return {
      detailData: {},
      synopsisData: [],
      courseId: this.$route.query.courseId,
      isWeixin: false,
      isAndroid: false,
      isIOS: false,
    };
  },
  watch: {
    tabKey: {
      handler(val) {
        if (val === "Catalogue") {
          this.getDetail();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    toBrowser() {
      const options = {
        scheme: {
          protocol: "wechatextmsg", //APP 协议
          host:"com.mm.example.starfamilypro"
        },
        intent: {
          package: "com.mm.example.starfamilypro",
          scheme: "wechatextmsg",
        },
        universal: {
          host: "xingjiaren-h5.hailiangedu.com",
        },
        appstore:
          "https://apps.apple.com/cn/app/%E6%98%9F%E5%AE%B6%E4%BA%BA/id1582745268?from=groupmessage&isappinstalled=0",
        yingyongbao: "https://sj.qq.com/appdetail/com.mm.example.starfamilypro",
        fallback: "https://xingjiaren-api.hailiangedu.com/app/download",
      };
      const callLib = new CallApp(options);
      callLib.open({
        param: {
          id: this.courseId,
        },
        path: "coursedetail",
      });
    },
    judgePhoneType() {
      var u = navigator.userAgent;
      var isWeixin = u.toLowerCase().indexOf("micromessenger") !== -1; // 微信内
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android终端
      var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      this.isWeixin = isWeixin;
      this.isIOS = isIOS;
      this.isAndroid = isAndroid;
      // 微信内
      if (isWeixin) {
        console.log("isWeixin");
      } else {
        if (isAndroid) {
          // Dialog.alert({ message: "isAndroid" });
        }
        Dialog.confirm({
          title: "",
          message: '即将离开微信,打开"星家人"',
        })
          .then(() => {
            this.toBrowser();
          })
          .catch(() => {});
      }
    },
    getDetail() {
      var u = navigator.userAgent;
      var isWeixin = u.toLowerCase().indexOf("micromessenger") !== -1; // 微信内
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android终端
      var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      this.isWeixin = isWeixin;
      this.isIOS = isIOS;
      this.isAndroid = isAndroid;
      this.$get("course/getCourseInfo", {
        courseId: this.courseId,
      })
        .then((catalogueData) => {
          this.detailData = catalogueData.data;
        })
        .catch(() => {});
      this.$get("course/courseRecommend", {
        pageNo: 1,
        pageSize: 4,
        courseId: this.courseId,
      })
        .then((synopsisData) => {
          this.synopsisData = synopsisData.data.records;
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.catalogue-content {
  padding: 15px 12px;
  padding-bottom: 125px;
  box-sizing: border-box;
}
.catalogue-content-info {
  font-size: 26px;
  color: rgba(75, 75, 75, 1);
}
.catalogue-content-bottom {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 20px;
  margin-bottom: 44px;
  flex-direction: column;
  flex-wrap: nowrap;
}
.catalogue-content-speaker {
  color: rgba(125, 125, 125, 1);
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.catalogue-content-tags {
  color: rgb(36, 128, 225);
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}
.catalogue-content-tagitem {
  margin: 0 4px;
  padding: 13px 21px;
  font-size: 24px;
  box-sizing: border-box;
  border-radius: 30px;
  display: flex;
  align-items: center;
  background-color: rgba(36, 127, 225, 0.1);
}

.catalogue-content-tagitem:last-child {
  margin-right: 0px;
}
.content-img {
  height: 60px;
  width: 100%;
}
.catalogue-content-speakinfo {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 15px;
}
.catalogue-content-speakinfo-img {
  width: 130px;
  height: 130px;
  border-radius: 50%;
}
.catalogue-content-speakdetail {
  margin-left: 25px;
  font-size: 24px;
  width: calc(100% - 100px);
}
.catalogue-content-speakinfo-user {
  font-size: 26px;
  width: 100%;
  color: #4b4b4b;
  margin-bottom: 12px;
}
.catalogue-content-speakinfo-content {
  color: rgba(125, 125, 125, 1);
  width: 100%;
}
::v-deep img {
  width: 100%;
  height: auto;
}
.catalogue-content-recommend-title {
  display: flex;
  font-size: 28px;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 12px;
  font-weight: bold;
  color: rgb(80, 80, 80);
}
.catalogue-content-recommend-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
}
.catalogue-content-recommend-box {
  padding: 4px;
  box-sizing: border-box;
  width: 50%;
}
.catalogue-content-recommend-title-img {
  height: 28px;
  width: auto;
  margin-right: 8px;
}
.catalogue-content-recommend-coverUrl {
  border-radius: 4px;
}
.catalogue-content-recommend-intro {
  overflow: hidden;
  font-size: 26px;
  margin-top: 21px;
  display: -webkit-box;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
}
.catalogue-content-recommend-speaker {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 21px;
  margin-bottom: 30px;
  color: #999;
  font-size: 20px;
}
.catalogue-content-recommend-speakerCoverUrl {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  margin-right: 10px;
}
</style>