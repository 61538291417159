<template>
  <div class="synopsis-content">
    <div
      @click="judgePhoneType"
      class="synopsis-content-info"
      v-for="(item, index) in detailData"
      :key="index"
    >
      <div class="synopsis-content-info-user">
        第{{ index + 1 }}节：
        <div class="synopsis-content-info-name">
          <img
            class="synopsis-content-info-video"
            src="../../../assets/img/video.png"
            alt=""
          />
          <div class="synopsis-content-info-name-m">
            {{ item.name }}
          </div>
        </div>
        <img
          class="catalogue-content-tagitem"
          src="../../../assets/img/synoplay.png"
          alt=""
        />
      </div>
      <div class="synopsis-content-info-time">
        视频时长：{{ formatSeconds(item.duration) }}
      </div>
    </div>
    <WxOpen v-if="isWeixin"> </WxOpen>
  </div>
</template>

<script>
import WxOpen from "./WxOpen.vue";
import { Dialog } from "vant";
import CallApp from "callapp-lib";
export default {
  name: "Synopsis",
  props: {
    tabKey: {
      type: String,
      default: "",
    },
  },
  components: {
    WxOpen,
  },
  data() {
    return {
      detailData: [],
      courseId: this.$route.query.courseId,
      isWeixin: false,
      isAndroid: false,
      isIOS: false,
    };
  },
  watch: {
    tabKey: {
      handler(val) {
        if (val === "Synopsis") {
          this.getDetail();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    toBrowser() {
      const options = {
        scheme: {
          protocol: "wechatextmsg", //APP 协议
          host:"com.mm.example.starfamilypro"
        },
        intent: {
          package: "com.mm.example.starfamilypro",
          scheme: "wechatextmsg",
        },
        universal: {
          host: "xingjiaren-h5.hailiangedu.com",
        },
        appstore:
          "https://apps.apple.com/cn/app/%E6%98%9F%E5%AE%B6%E4%BA%BA/id1582745268?from=groupmessage&isappinstalled=0",
        yingyongbao: "https://sj.qq.com/appdetail/com.mm.example.starfamilypro",
        fallback: "https://xingjiaren-api.hailiangedu.com/app/download",
      };
      const callLib = new CallApp(options);
      callLib.open({
        param: {
          id: this.courseId,
        },
        path: "coursedetail",
      });
    },
    judgePhoneType() {
      var u = navigator.userAgent;
      var isWeixin = u.toLowerCase().indexOf("micromessenger") !== -1; // 微信内
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android终端
      var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      this.isWeixin = isWeixin;
      this.isIOS = isIOS;
      this.isAndroid = isAndroid;
      // 微信内
      if (isWeixin) {
        console.log("isWeinxin")
      } else {
        if (isAndroid) {
          // Dialog.alert({ message: "isAndroid" });
        }
        Dialog.confirm({
          title: "",
          message: '即将离开微信,打开"星家人"',
        })
          .then(() => {
            this.toBrowser();
          })
          .catch(() => {});
      }
    },
    getDetail() {
      var u = navigator.userAgent;
      var isWeixin = u.toLowerCase().indexOf("micromessenger") !== -1; // 微信内
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android终端
      var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      this.isWeixin = isWeixin;
      this.isIOS = isIOS;
      this.isAndroid = isAndroid;
      this.$get("course/getCourseInfo", {
        courseId: this.courseId,
      })
        .then((catalogueData) => {
          this.detailData = catalogueData.data.contents;
        })
        .catch(() => {});
    },
    formatSeconds(value) {
      let theTime = parseInt(value); // 秒
      let theTime1 = 0; // 分
      let theTime2 = 0; // 小时

      if (theTime > 60) {
        theTime1 = parseInt(theTime / 60);
        theTime = parseInt(theTime % 60);

        if (theTime1 > 60) {
          theTime2 = parseInt(theTime1 / 60);
          theTime1 = parseInt(theTime1 % 60);
        }
      }
      let result = "" + parseInt(theTime) + "秒";
      if (theTime1 > 0) {
        result = "" + parseInt(theTime1) + "分" + result;
      }
      if (theTime2 > 0) {
        result = "" + parseInt(theTime2) + "小时" + result;
      }
      return result;
    },
  },
};
</script>

<style scoped>
.synopsis-content {
  padding: 15px 12px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}
.synopsis-content-info {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 15px;
  background: rgba(246, 246, 246, 0.8);
  border-radius: 15px;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 12px 18px;
  box-sizing: border-box;
}
.synopsis-content-info-video {
  width: 26px;
  height: 18px;
  margin-right: 8px;
}
.synopsis-content-info-name {
  width: 58vw;
  display: flex;
  flex: 1;
  align-items: center;
}
.synopsis-content-info-name-m {
  width: calc(100% - 22px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.synopsis-content-info-user {
  color: rgb(128, 128, 128);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 26px;
}
.catalogue-content-tagitem {
  cursor: pointer;
  box-sizing: border-box;
  height: 42px;
  display: flex;
  align-items: center;
}
.synopsis-content-info-time {
  color: rgb(174, 174, 174);
  font-size: 20px;
}
</style>